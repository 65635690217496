import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'

import { coreCompanyApiUrl } from '../../consts/url.const'
import { EntityApiBaseService } from '../entity-api-base/entity-api-base.service'
import { SettingParams } from 'src/app/models/setting.model'
import { take, tap } from 'rxjs/operators'
import { ICompany } from '@zonar-ui/auth/lib/models/company.model'
import { getCompanyString } from 'src/app/utils/get-company-string'
import { PermissionsService } from '@zonar-ui/auth'
import { UserSettings, OwnerType, SettingStatus } from 'src/app/consts/setting.const'
import { noop } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SettingApiService {
  private SETTING_RESOURCE = 'settings'
  selectedCompanyUserSettingId = ''

  // TODO: Temporary adding the text "beta2" into url as workaround.
  apiUrl = ''
  constructor(
    private http: HttpClient,
    private entityApiBaseService: EntityApiBaseService,
    private permissionsService: PermissionsService,
    @Inject('environment') @Optional() private environment: string,
    @Inject('region') @Optional() private region: string
  ) {
    // Parameter decorator to be used on constructor parameters, which marks the parameter as being an optional dependency. The DI framework provides null if the dependency is not found.
    if (!this.region) {
      this.region = 'NA'
    }

    if (!this.environment) {
      this.environment = 'development'
    }

    this.apiUrl = `${coreCompanyApiUrl[this.region][this.environment]}/core/v1beta2`
  }

  getSetting(params: any) {
    const url = `${this.apiUrl}/${this.SETTING_RESOURCE}`
    return this.entityApiBaseService.getData(url, params).pipe(
      tap((settings) => {
        const body = settings?.body

        if (body) {
          this.selectedCompanyUserSettingId = settings.body[0].id
        }
      })
    )
  }

  flushCompanySetting(company: ICompany) {
    const companyValue = getCompanyString(company)

    if (this.selectedCompanyUserSettingId) {
      const params: SettingParams = {
        value: companyValue,
      }
      this.updateSetting(params).subscribe(noop)
    } else {
      this.permissionsService
        .getUser()
        .pipe(take(1))
        .subscribe((user) => {
          const params: SettingParams = {
            ownerId: user.id,
            name: UserSettings.SELECTED_COMPANY,
            ownerType: OwnerType.USER,
            status: SettingStatus.ACTIVE,
            value: getCompanyString(company),
          }

          this.createSetting(params).subscribe(noop)
        })
    }
  }

  createSetting(params: SettingParams) {
    const url = `${this.apiUrl}/${this.SETTING_RESOURCE}`
    return this.http.post(url, params, { observe: 'response' })
  }

  updateSetting(params: SettingParams) {
    const url = `${this.apiUrl}/${this.SETTING_RESOURCE}/${this.selectedCompanyUserSettingId}`
    return this.http.patch(url, params, { observe: 'response' })
  }
}
