import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, merge, noop, Subject } from 'rxjs'
import { filter, take, takeUntil } from 'rxjs/operators'

import { PendoService } from '@zonar-ui/analytics'
import { I18nService } from '@zonar-ui/i18n'

import { APP_VERSION_STRING } from 'src/app/version'
import { environment } from 'src/environments/environment'
import { DataDogRumService } from './services/data-dog-rum/data-dog-rum.service'
import { currentLanguageCode$ } from './components/tree/services/language-dictionary-handling/language-dictionary-handling.service'
import { LANGUAGE_CODE_BY_LANGUAGE_ID } from './constants/internationalized-constants-en'
import { PermissionsService } from '@zonar-ui/auth'
import { AuthService } from '@auth0/auth0-angular'
import { OwnerType, SettingStatus, UserSettings } from './consts/setting.const'
import { SettingApiService } from './services/settings-api/setting-api.service'
import { getCompany } from './utils/get-company'
import { getCompanyString } from './utils/get-company-string'
import { SettingParams } from './models/setting.model'
import { parseCompanySettings } from './utils/parse-company-settings'
import { Store } from '@ngxs/store'

export let firstVisitUrl: string = null
export const resetFirstVisitUrl = () => (firstVisitUrl = null)

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loading = true
  translationsNotLoaded = true

  private destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(
    @Inject(APP_VERSION_STRING) private appVersion: string,
    private permissionService: PermissionsService,
    private renderer: Renderer2,
    private router: Router,
    private datadogRumService: DataDogRumService,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private pendoService: PendoService,
    private authService: AuthService,
    private settingApiService: SettingApiService,
    private store: Store
  ) {
    if (environment.region === 'EU') {
      this.renderer.addClass(document.body, 'zonar-conti-theme')
    } else {
      this.renderer.addClass(document.body, 'zonar-default-theme')
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        take(1)
      )
      .subscribe((event: NavigationStart) => {
        firstVisitUrl = event.url
      })

    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse())
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.translationsNotLoaded = false
          currentLanguageCode$.next(
            LANGUAGE_CODE_BY_LANGUAGE_ID[this.translateService.currentLang]
          )
        })
    }
  }

  ngOnInit() {
    console.log('ZONAR INC Ⓒ - ECT 2 ', `v${this.appVersion}`)
    console.log(`Environment: ${environment.environment}`)
    console.log('')

    // Make sure that at least the permission service has fetch all the neccessary
    // information before disable loading animation
    this.registerLoadingStateHandler()

    // set the company context
    this.setCompanyContext()

    // initialize pendo after we get the permissions
    this.permissionService
      .getPermissions()
      .pipe(filter((permissions) => Boolean(permissions)))
      .subscribe(() => {
        this.pendoService.initialize()
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }

  setCompanyContext() {
    // trigger the completion of the permissions logic so that PermissionsGuard can complete its observable
    combineLatest([
      this.permissionService.getCompaniesUser(),
      this.permissionService.getUser(),
    ]).subscribe(([companies, user]) => {
      if (companies.length === 1) {
        // user belongs to a single company
        this.permissionService.setCurrentCompanyContext({ id: companies[0] })

        this.router.navigate(['/companies', companies[0]])
      } else if (companies.length > 1) {
        // user belongs to more than one company, get the current selected company from the settings api
        const params = {
          ownerId: user.id,
          name: UserSettings.SELECTED_COMPANY,
          ownerType: OwnerType.USER,
          status: SettingStatus.ACTIVE,
        }
        // lookup the selected company from the settings api
        this.settingApiService
          .getSetting(params)
          .pipe(take(1))
          .subscribe((res) => {
            const selectedCompanies = res.body

            if (selectedCompanies?.length > 0) {
              const selectedCompany = parseCompanySettings(selectedCompanies[0].value)

              this.permissionService.setCurrentCompanyContext(selectedCompany)

              this.router.navigate(['/companies', selectedCompany.id])
            } else {
              // use the first company
              getCompany(companies[0]).subscribe((company) => {
                this.permissionService.setCurrentCompanyContext(companies[1])

                this.settingApiService.flushCompanySetting(company)
              })
            }
          })
      }
    })
  }

  private registerLoadingStateHandler() {
    const emptyPerms$ = this.permissionService
      .getIsFetchPermsSuccess()
      .pipe(filter((result) => !result))
    merge(emptyPerms$, this.permissionService.getPermissions())
      .pipe(take(1))
      .subscribe(() => {
        this.loading = false
      })
  }
}
