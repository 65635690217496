import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { combineLatest, defer, EMPTY, forkJoin, iif, Observable, of } from 'rxjs'
import { catchError, expand, map, reduce, switchMap, tap } from 'rxjs/operators'

import { PermissionsService } from '@zonar-ui/auth'

import { environment } from 'src/environments/environment'
import {
  AssetViewModel,
  AssetViewUploadModel,
  ConfigurationModel,
  EvirLanguageDictionary,
  SettingModel,
  TranslationObject,
} from '../components/tree/models/tree.model'
import { CompanyModel } from '../views/companies/model'
import {
  blankPieces,
  RawTemplateLibraryPieceModel,
  TemplateLibraryPieceModel,
} from '../components/template-library/models/template-library.model'
import { CheckDuplicatePieceNameService } from '../components/template-library/services/check-duplicate-piece-name/check-duplicate-piece-name.service'

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  endpoint = `${environment.companyApi}`
  dataConfig = `${environment.configApi}`
  evirApi = `${environment.evirApi}`
  fetchPageSize = 500
  firstValueRequest = 1

  constructor(
    private http: HttpClient,
    private pieceNameService: CheckDuplicatePieceNameService,
    private permissionService: PermissionsService
  ) {}

  public getCompanyList(): Observable<CompanyModel[]> {
    return combineLatest([
      this.permissionService.getIsZonarUser(),
      this.permissionService.getCompanyMap(),
    ]).pipe(
      switchMap(([isZonarUser, companyListByUser]) => {
        return iif(
          () => Boolean(isZonarUser),
          defer(() => this.getAllCompanies()),
          of(
            Object.keys(companyListByUser).map(
              (key) => companyListByUser[key]
            ) as unknown as CompanyModel[]
          )
        )
      })
    )
  }

  public getConfiguration(companyId: string): Observable<ConfigurationModel | {}> {
    return forkJoin([
      this.getConfigurationObject(companyId),
      this.getTranslationObject(companyId),
      this.getEvirLanguageDictionary(companyId),
      this.getEvirSettings(companyId),
    ]).pipe(
      switchMap(
        ([
          configurationObject,
          translationObject,
          evirLanguageDictionary,
          { maxDefectImageCount },
        ]) => {
          if (!configurationObject || !evirLanguageDictionary) {
            return of({})
          }

          return of({
            ...configurationObject,
            translationObject: translationObject,
            evirLanguageDictionary: evirLanguageDictionary,
            maxDefectImageCount: maxDefectImageCount,
          })
        }
      ),
      catchError(() => of({}))
    )
  }

  public updateConfiguration(
    configurationId: string,
    configurationObject: ConfigurationModel,
    companyId: string,
    deploy: boolean,
    translationObject: TranslationObject = {}
  ): Observable<ConfigurationModel> {
    if (deploy || Object.keys(translationObject || {}).length === 0) {
      return this.updateConfigurationObject(
        configurationId,
        configurationObject,
        companyId,
        deploy
      )
    }

    return forkJoin([
      this.updateConfigurationObject(
        configurationId,
        configurationObject,
        companyId,
        deploy
      ),
      this.updateTranslationObject(companyId, translationObject),
    ]).pipe(switchMap(([configResponse]) => of(configResponse)))
  }

  public getLegacyAccountCode(companyId: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.endpoint}/companies/${companyId}/legacy-account-codes`
    )
  }

  public getAssetViewBackground(
    companyId: string,
    assetViewId: string
  ): Observable<string> {
    if (!assetViewId) {
      return of('')
    }
    return this.http.get(
      `${this.evirApi}/asset-views/${assetViewId}?companyId=${companyId}`,
      { responseType: 'text' }
    )
  }

  /**
   * Fetch all asset views from API. Generally use the header 'x-page-count' to determine if the request is for
   * last page or not. In case if 'x-page-count' is less than 1 or not a number (should not happen in normal case)
   * it will stop fetching and return partial data.
   *
   * @param companyId target company ID
   * @param [itemPerPage=100] number of item should be fetched for each request, the larger the less number of requests
   * but will put more stress on the server
   * @return list of asset view
   */
  public getAllAssetView(
    companyId: string,
    itemPerPage = 100
  ): Observable<AssetViewModel[]> {
    const pageCountHeader = 'x-page-count'
    const getAssetViewByPage = (page, perPage) =>
      this.http.get(
        `${this.evirApi}/asset-views?companyId=${companyId}&page=${page}&perPage=${perPage}`,
        { responseType: 'json', observe: 'response' } // need to set observe: 'response' to get the whole response
      )
    let currentPage = 1

    // `expand` will call each request sequentially, in case if we want to improve speed,
    // consider call the requests concurrently using `mergeMap` for example.
    return getAssetViewByPage(currentPage, itemPerPage).pipe(
      expand((res) => {
        let totalPage = parseInt(res.headers.get(pageCountHeader), 10)
        if (isNaN(totalPage) || totalPage < 1) {
          console.error(
            `Response returns ${pageCountHeader} that is not a number or less than 1`
          )
          totalPage = currentPage // to make the whole loop stop or else it will run forever
        }
        const isFinalPage = currentPage === totalPage
        currentPage++
        return isFinalPage ? EMPTY : getAssetViewByPage(currentPage, itemPerPage)
      }),
      reduce((acc, res) => acc.concat(res.body), [])
    )
  }

  public uploadAssetViewBackground(
    fileUpload: AssetViewUploadModel
  ): Observable<AssetViewUploadModel> {
    return this.http.post<AssetViewUploadModel>(`${this.evirApi}/asset-views`, fileUpload)
  }

  public getTemplateLibraryPieces(
    companyId: string
  ): Observable<TemplateLibraryPieceModel[]> {
    return this.http
      .get<TemplateLibraryPieceModel[]>(
        `${this.dataConfig}/tl-pieces?companyId=${companyId}`
      )
      .pipe(
        catchError(() => of(null)),
        tap((pieceList) => {
          if (
            !this.pieceNameService.isPieceNamesPreloaded() &&
            pieceList &&
            pieceList.length
          ) {
            this.pieceNameService.preloadPieceNames(pieceList)
          }
        })
      )
  }

  public deleteTemplateLibraryPiece<T>(pieceId: string): Observable<T> {
    return this.http.delete<T>(`${this.dataConfig}/tl-pieces/${pieceId}`)
  }

  public updateTemplateLibraryPiece<T>(
    id: string,
    data: TemplateLibraryPieceModel,
    isAdmin: boolean
  ): Observable<T> {
    return this.http.put<T>(`${this.dataConfig}/tl-pieces/${id}?admin=${isAdmin}`, data)
  }

  public getBlankPieces(): Observable<TemplateLibraryPieceModel[]> {
    return of(blankPieces)
  }

  public addTemplateLibraryPiece(
    newPiece: RawTemplateLibraryPieceModel,
    isAdmin: boolean
  ): Observable<TemplateLibraryPieceModel> {
    return this.http
      .post<TemplateLibraryPieceModel>(
        `${this.dataConfig}/tl-pieces?admin=${isAdmin}`,
        newPiece
      )
      .pipe(tap((piece) => this.pieceNameService.addPieceName(piece.name, piece.id)))
  }

  private getAllCompanies(): Observable<CompanyModel[]> {
    const callStack: Observable<CompanyModel[]>[] = []

    return this.getTotalRecord().pipe(
      switchMap((totalRecord) => {
        const fetchTimes = Math.ceil(totalRecord / this.fetchPageSize)
        for (let i = 1; i < fetchTimes + 1; ++i) {
          callStack.push(this.getCompanies(i, this.fetchPageSize))
        }
        return forkJoin(callStack).pipe(
          switchMap((res) => {
            return of(res.reduce((acc, current) => acc.concat(current), []))
          })
        )
      })
    )
  }

  private getTotalRecord(): Observable<number> {
    return this.http
      .get(
        `${this.endpoint}/companies?status=ACTIVE&page=${this.firstValueRequest}&per_page=${this.firstValueRequest}`,
        { observe: 'response' }
      )
      .pipe(switchMap((res) => of(Number(res.headers.get('x-total-count')) || 0)))
  }

  private getEvirSettings(companyId: string): Observable<SettingModel> {
    return this.http
      .get<SettingModel[]>(
        `${this.evirApi}/evir-settings?companyId=${companyId}&activeOnly=true`
      )
      .pipe(switchMap(([settingResponse]) => of(settingResponse)))
  }

  private getCompanies(page: number, fetchPageSize: number = 100) {
    return this.http.get<CompanyModel[]>(
      `${this.endpoint}/companies?status=ACTIVE&page=${page}&per_page=${fetchPageSize}`
    )
  }

  private getConfigurationObject(companyId: string): Observable<ConfigurationModel> {
    return this.http.get<ConfigurationModel>(
      `${this.dataConfig}/configs?companyId=${companyId}`
    )
  }

  private getTranslationObject(companyId: string): Observable<TranslationObject> {
    return this.http.get<TranslationObject>(
      `${this.dataConfig}/translations?companyId=${companyId}`
    )
  }

  private getEvirLanguageDictionary(
    companyId: string
  ): Observable<EvirLanguageDictionary> {
    return this.http.get<EvirLanguageDictionary>(
      `${this.evirApi}/lang-dict?companyId=${companyId}`
    )
  }

  private updateConfigurationObject(
    configurationId: string,
    configurationObject: ConfigurationModel,
    companyId: string,
    deploy: boolean
  ): Observable<ConfigurationModel> {
    return this.http.put<ConfigurationModel>(
      `${this.dataConfig}/configs/${configurationId}?deploy=${deploy}&companyId=${companyId}`,
      configurationObject
    )
  }

  private updateTranslationObject(
    companyId: string,
    translationObject: TranslationObject
  ): Observable<{}> {
    return this.http.post(`${this.dataConfig}/translations`, {
      companyId,
      newTranslations: translationObject,
    })
  }
}
